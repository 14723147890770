input {
  border: none;
  border-bottom: #3f51b5 solid 3px !important; ;
  background: transparent;
  width: 100%;
  padding: 3px;
  &:focus {
    outline: none;
    transform: scale(1.01);
  }
}


::-webkit-input-placeholder { /* Chrome */
  color: #3f51b5 !important;;
  font-weight: 700;
}
:-ms-input-placeholder { /* IE 10+ */
  color: #3f51b5 !important;;
  font-weight: 700;
}
::-moz-placeholder { /* Firefox 19+ */
  color: #3f51b5 !important;;
  font-weight: 700;
}
:-moz-placeholder { /* Firefox 4 - 18 */
  color: #3f51b5 !important;;
  font-weight: 700;
}
