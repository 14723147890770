@import "null";

.page {
    min-height: 100%;
    min-height: 100vh;
    height: 100vh;
    display: flex;
    flex-direction: column;
}
.content {
    flex: 1 1 auto;
    overflow-y: auto;
    min-height: 0px;
    display: flex;
    flex-direction: row;
}
.side-bar {
    overflow-y: auto;
    min-height: 0px;
    height: 100%;
}
.main-bar {
    position: relative;
    flex: 1 1 auto;
    overflow-y: auto;
    min-height: 0px;
}

.ReactVirtualized__Grid__innerScrollContainer[style] {
    width: 100% !important;
}

.ag-header-cell-filtered {
    background-color: #1b6d85 !important;
    color: #fff !important;
}

.ag-header-cell-filtered span {
    color: #fff !important;
}

.cell-wrap-text {
    white-space: normal !important;
    line-height: 23px !important;
}
.link_wrapper {
  display: flex;
  align-items: center;
  position: fixed;
  right: 5px;
}

.link_registration {
  margin-left: auto;
  margin-right: 0;
  a {
    color: white;
  }
}
.publisher_p{
  margin-right: 15px;
}
.link_login{
  margin-left: 10px;
  margin-right: 10px;

  a {
    color: white;
  }
}

.link_logout {
  a {
    color: white;
  }
}

.btn_reports {
  //margin-left: 65%;
  //margin-right: 0;
  margin-right: 5px;
}

.link_logout {
  margin-left: auto;
  margin-right: 5px;
  &:hover {
    cursor: pointer;
    background-color: #5461ab !important;
    transition: 0.6s linear;
  }
}

.btn_nav_link {
  //text-transform: lowercase !important;
  //font-size: 1rem;
  //font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  //font-weight: 400;
  //line-height: 1.5;
  //letter-spacing: 0.00938em;
  //padding: 15px !important;
  padding: 15px !important;
  display: block;
  font-size: 100% !important;
  line-height: 1.15 !important;
  margin: 0 !important;
}

.MuiButton-text-btn {
  padding: 15px !important;
  text-transform: none !important;
  font-size: 100% !important;
  line-height: 1.15 !important;
  margin: 0 !important;
}

.react-confirm-alert-overlay{
  background: rgba(255, 255, 255, 0.2) !important;
}
