.overlay {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 10000;
    background: linear-gradient(
                     rgba(255,255,255, .7), 
                     rgba(255,255,255, .7));
}
.loader {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}
