.authorization {
  display: flex;
  flex-direction: column;
  width: 600px;
  //background-color: white;
  border-radius: 20px;
  padding: 30px;
  align-self: center;
  position: absolute;
  left: 50%;
  top: 40%;
  margin-left: -300px;
  margin-top: -200px;

  input {
    margin: 10px 20px;
  }
}

.authorization_header {
  padding: 20px 0;
  font-size: 30px;
  font-weight: 700;
  text-align: center;
}

.authorization_select {
  margin-left: 20px;
  margin-right: -20px;
}

.authorization_wrapper_select {
  width: 100%;
}

.authorization_btn {
  border: none;
  background-color: #3f51b5 !important;;
  border-radius: 5px;
  color: white;
  font-weight: 800;
  padding: 10px 10px;
  align-self: flex-end;
  //margin-top: 10px;
  width: 45%;
  align-items: inherit;
  justify-content: inherit;
  //margin: 10px 4px;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  //font-weight: 500;
  line-height: 1.75;
  //border-radius: 4px;
  letter-spacing: 0.02857em;
  &:hover {
    cursor: pointer;
    background-color: #5461ab !important;
    transition: 0.6s linear;
  }
}

.add_publishment_btn {
  border: none;
  background-color: #3f51b5 !important;;
  border-radius: 5px;
  color: white;
  font-weight: 800;
  padding: 10px 10px;
  align-self: flex-end;
  //margin-top: 10px;
  width: 95%;
  align-items: inherit;
  justify-content: inherit;
  //margin: 10px 4px;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  //font-weight: 500;
  line-height: 1.75;
  //border-radius: 4px;
  letter-spacing: 0.02857em;
  &:hover {
    cursor: pointer;
    background-color: #5461ab !important;
    transition: 0.6s linear;
  }
}

.authorization_btn_cancel {
  background-color: #E0E0E0;
  border: none;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 500;
  line-height: 1.75;
  border-radius: 4px;
  letter-spacing: 0.02857em;
  //text-transform: uppercase;
  //padding: 10px 10px;
  padding: 10px;
  //padding-bottom: 10px;
  align-self: flex-end;
  //margin-top: 10px;
  width: 40%;
  align-items: inherit;
  justify-content: inherit;
  //margin: 10px 4px;
  //border-radius: 4px;
  &:hover {
    cursor: pointer;

    transition: 0.6s linear;
    border-radius: 4px;
    //letter-spacing: 0.02857em;
    //text-transform: uppercase;
  }
}
.autorization_wrapper {
  display: inline-flex;
  gap: 20px;
  justify-content: flex-end;
  align-items: center;
}

.authorization_modal {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 80vw;
  height: 80vh;
  background-color: #fff;
  border: 2px solid #000;
  box-shadow: 0px 11px 15px -7px rgba(0,0,0,0.2), 0px 24px 38px 3px rgba(0,0,0,0.14), 0px 9px 46px 8px rgba(0,0,0,0.12);
  padding: 32px;
  overflow-y: scroll;
}

